@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

p {
  margin: 0;
}

div,
p,
span,
h1,
h1,
h3,
h4,
h5,
h6 {
  @media (min-width: $xs) {
    white-space: pre-wrap;
  }
}

.fancybox__container {
  z-index: 8000 !important;
}

.fancybox__video,
.fancybox__iframe {
  background: 0 0;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.fancybox__iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox__content > .carousel__button.is-close {
  position: fixed !important;
  width: 48px;
  height: 48px;
  right: 0px !important;
  top: 0px !important;

  @media (min-width: $lg) {
    right: 8px !important;
    top: 8px !important;
  }
}

#onetrust-policy-title {
  font-family: 'GT Eesti Pro Display', serif !important;
  font-size: 1.8em;
}

#onetrust-accept-btn-handler,
#onetrust-pc-btn-handler {
  width: 100% !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  border-radius: 20px !important;
}

#ot-sdk-btn.ot-sdk-show-settings {
  font-family: 'GT Eesti Pro Display', sans-serif !important;
  font-size: 1.2em !important;
  color: #ffffff !important;
  border: #072241 !important;
  background-color: #072241;
  margin-bottom: 2%;
  margin-top: 2%;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  color: #ffffff !important;
  border: #072241 !important;
  background-color: #004d69 !important;
}

.ot-sdk-container {
  width: 100%;
  max-width: 1060px !important;
  font-family: 'GT Eesti Pro Display', sans-serif !important;
  font-size: 1.2em !important;
  line-height: 1.4;

  @media (min-width: 1280px) {
    width: 100%;
    max-width: 1060px;
  }

  @media (min-width: 1024px) {
    width: 100%;
    max-width: 1060px;
  }

  & * {
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
  }
}

@media (min-width: 896px) {
  #onetrust-banner-sdk.otFlat {
    max-height: 90% !important;
    padding: 40px !important;
  }
}

#onetrust-button-group {
  margin-right: 0 !important;
  margin-top: 1.25em !important;
  width: 100% !important;
}

@media (min-width: 896px) {
  #onetrust-button-group-parent {
    margin-top: 24px;
    padding-left: 6% !important;
    transform: translateY(-50%) !important;
    top: 65% !important;
  }
}

@media (min-width: 360px) {
  #onetrust-button-group-parent {
  }
}

#onetrust-pc-sdk.ot-fade-in,
.onetrust-pc-dark-filter.ot-fade-in,
#onetrust-banner-sdk.ot-fade-in {
  animation-name: unset !important;
}

.onetrust-pc-dark-filter {
  background: transparent !important;
}

#onetrust-banner-sdk.otFlat {
  animation-duration: 0s !important;
  animation-name: unset !important;
  bottom: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
