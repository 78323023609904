@media (min-width: 1024px) {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #072241;
    &.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      // pointer-events: none;
    }
    &::after,
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 15px;
      height: 2px;
      background-color: #072241;
    }
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    &:after {
      top: 28px;
      transform: rotate(45deg);
    }
    &:before {
      transform: rotate(-45deg);
      top: 18px;
    }
    left: 20px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    &:after {
      top: 28px;
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
      top: 18px;
    }
    right: 20px;
    left: auto;
  }

  .swiper-button-lock {
    display: none;
  }
}
