@import '@/styles/_variables';

#whr_embed_hook {
  box-sizing: border-box;
}


#whr_embed_hook ul.whr-items {
  list-style-type: none;
  padding: 0;
}

#whr_embed_hook h2 {
  font-size: 34px;
  font-family: $font-gt-display;
  line-height: 42px;
  color: #09263a;
}

#whr_embed_hook h2 a {
  font-weight: 600;
  font-size: 25px;
  color: #09263a;
  font-family: $font-gt-display;
}

#whr_embed_hook li.whr-item {
  border: 1px solid #ededed;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  transition: box-shadow .2s ease;
  padding-right: 20px;
  padding-left: 20px;
  font-family: $font-gt-display;
}

#whr_embed_hook li.whr-item h3.whr-title a {
  color: navy;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  font-family: $font-gt-display;
}

#whr_embed_hook li.whr-item ul.whr-info {
  padding: 0;
  list-style-type: none
}

#whr_embed_hook li.whr-item ul.whr-info .whr-location, #whr_embed_hook li.whr-item ul.whr-info span {
  margin-top: 14px;
  color: #333e49;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  font-family: $font-gt-display;


}

#whr_embed_hook li.whr-item ul.whr-info .whr-date, #whr_embed_hook li.whr-item ul.whr-info span {
  color: #333e49;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  font-family: $font-gt-display;

}

#whr_embed_hook li.whr-item:hover {
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .26), 0 4px 18px 0 rgba(0, 0, 0, .19)
}