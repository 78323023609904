html,
body {
  min-height: 100%;
  font-size: 16px;
  font-weight: 400;
}
body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
}

* {
  outline: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  border: none;
  font-family: inherit;
}

input,
textarea {
  font-family: inherit;
}
